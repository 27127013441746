import React, { useState } from "react"

import range from 'lodash/range'

import classes from 'utils/classes'

import { useTranslation } from 'react-i18next'

import css from './SelectButton.module.scss'

const SelectButton = ({ cb, value = null, defaultYear = null }) => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [year, setYear] = useState(defaultYear || value)

  return (
    <div className={css.selectBox}>
      <div className={classes(css.selectInput, opened && css.openedInput)} onClick={() => setOpened(!opened)}>
        <div className={css.selectYear}>{year || t('All time')}</div>
        <div className={classes(css.selectButton, opened && css.rotateButton)}>
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L9 1" stroke="black"/>
          </svg>
        </div>
      </div>
      <div className={classes(css.selectList, opened && css.openedList)}>
        <ul>
          <li className={css.selectItem}
              onClick={() => {
                setOpened(!opened)
                setYear(null)
                cb && cb(null)
              }
          }>
            {t('All time')}
          </li>
          {range(2020, 1990).map(i =>
            <li key={i}
                className={css.selectItem}
                onClick={() => {
                  setOpened(!opened)
                  setYear(i)
                  cb && cb(i)
                }}
            >
              {i}
            </li>)}
        </ul>
      </div>
    </div>
  )
}

export default SelectButton
