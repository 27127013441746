import React from "react"

import { navigate } from "@reach/router"

import PageDocuments from "components/PageDocuments/PageDocuments"

import Layout from "components/Layout/Layout"

import useChangeLanguage from "hooks/useChangeLanguage"

const Documents = ({ pageContext }) => {
  const { documents, page, lang } = pageContext
  useChangeLanguage(lang)

  if (!pageContext || !pageContext.page) {
    navigate("page/1/")
    return null
  }

  return (
    <Layout>
      <PageDocuments documents={documents} page={page} />
    </Layout>
  )
}

export default Documents
