import React, { useEffect } from 'react'

import withBlackHeader from 'HOC/withBlackHeader'

import { useLocation } from '@reach/router'

import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import { useMediaQuery } from 'react-responsive'

import useCategories from 'hooks/useCategories'

import classes from 'utils/classes'
import checkIsSSR from 'utils/checkIsSSR'

import store from 'state/documentsStore'

import Seo from 'components/seo'

import Pagination from 'components/common/Pagination/Pagination'
import PopupFilters from 'components/common/PopupFilters/PopupFilters'
import Document from './Document/Document'
import Filters from './Filters/Filters'

import css from './PageDocuments.module.scss'

const PageDocuments = ({ page = 1, documents: documentsList }) => {
  const { documents } = store

  const { search } = useLocation()

  const categoriesList = useCategories()
  const documentsListFront = useStore(documents.store)

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const documentsArray = documentsListFront

  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  const basePath = `/${language}/documents`

  useEffect(() => {
    fetchData()
  }, [page, search])

  const fetchData = () => {
    const filter = { page }
    const query = new URLSearchParams(search)
    const year = query.get("year")
    const categories = query.get("categories")

    filter[`translated_${language}`] = true

    if (year) {
      filter["pub_date__year"] = year
    }
    if (categories) {
      filter["categories__id__in"] = categories
    }

    documents.fetch(filter)
  }

  const getCategoryTitle = catId => {
    const cat = categoriesList.results.find(({ id }) => id === catId)

    if (!cat) return ''

    return cat.name
  }

  const fullCategoryList = cats => cats.map(getCategoryTitle).join(", ");

  const onClientSide = !checkIsSSR();

  return (
    <>
      <Seo
        title={documentsList.metaTitle}
        description={documentsList.metaDescription}
        keywords={documentsList.metaKeywords}
      />
      <div className={css.container}>
        <div className={css.title}>
          <h1>{t("Reports and policies")}</h1>
        </div>
        <div className={css.filters}>
          {!isMobile && onClientSide && <Filters/>}
          {isMobile && onClientSide && <PopupFilters><Filters/></PopupFilters>}
        </div>
        <div
          className={classes(
            css.list,
            css.load,
            documentsListFront.isLoading && css.loading
          )}
        >
          {documentsArray.results.map(item => (
            <Document
              key={item.id}
              type={fullCategoryList(item.categories)}
              title={item.title}
              files={item.files}
            />
          ))}
        </div>
        {documentsArray.pagesCount > 1 && (
          <Pagination
            basePath={basePath}
            page={parseInt(page)}
            pagesCount={documentsArray.pagesCount}
            countToShow={isMobile ? 1 : 3}
            query={location.search.toString()}
          />
        )}
      </div>
    </>
  )
}

export default withBlackHeader(PageDocuments)
