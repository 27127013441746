import React from "react"
import { useTranslation } from "react-i18next"
import { navigate, useLocation } from "@reach/router"

import useCategories from "hooks/useCategories"

import SelectButton from "components/common/SelectButton/SelectButton"
import MenuButton from "components/common/MenuButton/MenuButton"

import css from "../PageDocuments.module.scss"

const Filters = () => {
  const { t, i18n: { language } } = useTranslation()

  const categoriesList = useCategories()

  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const year = query.get("year")
  const queryCategories = query.get("categories")
  const categories =
    queryCategories?.split(",").map(category => +category) || []

    const updatePathByFilters = () => {
      const pathname = `/${language}/documents/page/1/`
      const params = query.toString()
      const path = params ? `${pathname}?${params}` : pathname

      navigate(path)
    }

  const onChangeYear = selectedYear => {
    if (selectedYear) {
      query.set("year", selectedYear)
    } else {
      query.delete("year")
    }

    updatePathByFilters()
  }

  const onSelectCategory = clickedCategory => isChecked => {
    let selectedCategories = [...categories]

    if (isChecked) {
      selectedCategories.push(clickedCategory)
    } else {
      selectedCategories = categories.filter(
        category => category !== clickedCategory
      )
    }

    if (selectedCategories.length > 0) {
      query.set("categories", selectedCategories)
    } else {
      query.delete("categories")
    }

    updatePathByFilters()
  }

  return (
    <>
      <div className={css.filter}>
        {t("Categories")}
        <div className={css.filterList}>
          {categoriesList.results.map(item => (
            <MenuButton
              key={item.id}
              cb={onSelectCategory(item.id)}
              checked={categories.includes(item.id)}
            >
              {item.name}
            </MenuButton>
          ))}
        </div>
      </div>
      <div className={css.select}>
        {t("Year")}
        <SelectButton cb={onChangeYear} value={year} />
      </div>
    </>
  )
}

export default Filters
