import React from 'react'

import classes from 'utils/classes'

import css from '../MenuButton/MenuButton.module.scss'

const MenuButton = ({ children, cb, checked = false, className }) => {
  return (
      <button
        className={classes(css.button, checked && css.clicked, className)}
        onClick={() => {
          if (!cb) return

          cb(!checked)
        }}
      >
        {children}
      </button>
  )
}

export default MenuButton
