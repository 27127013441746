import React from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Link } from 'gatsby'

import classes from 'utils/classes'

import css from './Pagination.module.scss'

const Item = ({ value, active, basePath, query }) => (
  <Link
    className={classes(css.item, active && css.active)}
    to={`${basePath}/page/${value}${query}`}
  >
    {value}
  </Link>
)

const Pagination = ({ page, pagesCount, basePath = '', countToShow = 3, query = '' }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  const getPrevItems = () => {
    const list = []
    const elementsToShow = Math.min(countToShow, page - 1)

    for (let i = 0; i < elementsToShow; i++) {
      list.push(<Item basePath={basePath} key={i} value={i + 1} />)
    }
    if (elementsToShow + 2 < page) {
      list.push(<span key="dots">...</span>)
    }
    if (page > countToShow + 1) {
      list.push(<Item basePath={basePath} key={"prev"} value={page - 1} />)
    }
    return list
  }

  const getNextItems = () => {
    const list = []
    const elementsToShow = Math.min(countToShow, pagesCount - page)

    if (elementsToShow < pagesCount - page) {
      list.push(<Item basePath={basePath} key={"next"} query={query} value={page + 1} />)
    }

    if (elementsToShow + 1 < pagesCount - page) {
      list.push(<span key="dots">...</span>)
    }

    for (let i = pagesCount - elementsToShow; i < pagesCount; i++) {
      list.push(<Item query={query} basePath={basePath} key={i} value={i + 1} />)
    }
    return list
  }

  const showPrev = page > 2
  const showNext = page < pagesCount - 1

  return <div className={css.container}>
    {isMobile && <>
      {showPrev && <Link to={`${basePath}/page/${page - 1}${query}`} className={css.prev}>← {t('Previous page')}</Link>}
      {showNext && <Link to={`${basePath}/page/${page + 1}${query}`} className={css.next}>{t('Next page')} →</Link>}
      <div className={css.list}>
        {getPrevItems()}
        <Item active basePath={basePath} value={page} query={query} />
        {getNextItems()}
      </div>
    </>}
    {!isMobile && <>
      {showPrev && <Link to={`${basePath}/page/${page - 1}${query}`} className={css.prev}>← {t('Previous page')}</Link>}
      {getPrevItems()}
      <Item active basePath={basePath} value={page} query={query} />
      {getNextItems()}
      {showNext && <Link to={`${basePath}/page/${page + 1}${query}`} className={css.next}>{t('Next page')} →</Link>}
    </>}
  </div>

}

export default Pagination
