import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Popup from '../Popup/Popup'

import classes from 'utils/classes'

import css from './PopupFilters.module.scss'

const Sign = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.7" cx="10" cy="10" r="9.5" stroke="black"/>
    <rect x="9.87235" y="3.20523" width="0.25641" height="13.5897" stroke="black" strokeWidth="0.25641"/>
    <rect x="16.7956" y="9.87174" width="0.25641" height="13.5897" transform="rotate(90 16.7956 9.87174)" stroke="black" strokeWidth="0.25641"/>
  </svg>
)

const PopupFilters = ({ children, ...other }) => {
  const { t } = useTranslation()

  const [opened, setOpened] = useState(false)

  const CloseButton = () => (
    <button className={classes(css.button, css.close)} onClick={() => setOpened(false)}>
      {t('Filters')}
      <Sign />
    </button>
  )

  return <>
    <button className={css.button} onClick={() => setOpened(true)}>
      {t('Filters')}
      <Sign />
    </button>
    <Popup
      topChild={<CloseButton />}
      buttonText={t('Apply filters')}
      buttonCallback={() => setOpened(false)}
      opened={opened}
      {...other}
    >
      {children}
    </Popup>
  </>
}

export default PopupFilters
