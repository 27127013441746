import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const useCategories = () => {
  const { i18n: { language } } = useTranslation()
  const { documentCategoriesEn, documentCategoriesRu } = useStaticQuery(
    graphql`
      query {
        documentCategoriesEn {
          results {
            name
            id
          }
        }
        documentCategoriesRu {
          results {
            name
            id
          }
        }
      }
    `
  )
  if (language === 'en') return documentCategoriesEn
  return documentCategoriesRu
}

export default useCategories
