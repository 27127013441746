import React from 'react'

import fileSize from 'filesize.js'

import css from './Document.module.scss'

const Document = ({ type, title, files }) => {
  return <div className={css.container}>
    <p className={css.type}>{type}</p>
    <h3 className={css.title}>{title}</h3>
    {files && files.length > 0 && <div className={css.files}>
      {files.map(file => (
        <div className={css.file} key={file.id}>
          <a href={file.file} target="_blank" rel="nofollow noopener noreferrer">
            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 8L0 4H8L4 8Z" fill="#6B7B8C"/>
              <line x1="4" y1="5" x2="4" y2="-4.37114e-08" stroke="#6B7B8C" strokeWidth="2"/>
              <line x1="7.92188" y1="9.9231" x2="0.0756341" y2="9.9231" stroke="#6B7B8C" strokeWidth="2"/>
            </svg>
            {file.name}
            <span className={css.extension}>
              {file.extension.toUpperCase()}, {fileSize(file.fileSize)}
            </span>
          </a>
        </div>
      ))}
    </div>}
  </div>
}

export default Document
