import { getCategories, getDocuments } from '../api'

import generatorList from './fabrics/list'

const categoriesFabric = generatorList({
  title: 'categories',
  fetch: getCategories,
  fieldsToTranslate: ['name'],
})

const documentsFabric = generatorList({
  title: 'documents',
  fetch: getDocuments,
  withClear: false,
  fieldsToTranslate: [
    'type',
    'title',
    { key: 'files', fieldsToTranslate: ['name', 'file'] },
  ],
  rootFieldsToTranslate: ['metaTitle', 'metaDescription', 'metaKeywords', 'metaImage'],
})

export default {
  categories: {
    ...categoriesFabric,
    fetch: categoriesFabric.fetchEffect,
    store: categoriesFabric.store,
  },
  documents: {
    ...documentsFabric,
    fetch: documentsFabric.fetchEffect,
    store: documentsFabric.store,
  },
}
